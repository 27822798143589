<template>
  <div id="apps" v-if="info">
      <van-nav-bar title="订单详情" left-arrow @click-left="$router.push('/usercenter')"/>
    <div class="ddxq clear">
      <div class="yijingsy clear" v-if="info.orderStatus >= 2 && info.orderStatus < 5">
        <div id="productStatus">
          <span>
            <em>{{
              info.orderStatus == 2
                ? " 未使用"
                : info.orderStatus == 4
                ? "已使用"
                : ""
            }}</em>
          </span>
                </div>
                <div class="hex clear">
                    <label>核销码</label><span>{{ info.writeOffCode }}</span>
                </div>
                <div class="erwem">
                    <img :src="info.writeOffCodeUrl" alt="" style="width: 100%"/>
                </div>
                <div style="margin-top: 40px">
                    <p>请将二维码展示给商家核销</p>
                    <template v-if="info.isLongValidate">
                        <p class="p">长期有效</p>
                    </template>
                    <p class="p" v-else>
                        有效期：{{ info.formatValidateStartTime }} —
                        {{ info.formatValidateEndTime }}
                    </p>
                </div>
            </div>
            <div class="headss clear">
                <div class="ccv clear">
                    <p>买家信息</p>
                    <div class="text">
                        <div>
                            <p>姓名：{{ info.productOrderBuyer.name }}</p>
                        </div>
                        <div>
                            <p>电话：{{ info.productOrderBuyer.phone}}</p>
                        </div>
                        <div>
                            <p>留言：{{info.remark}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tect clear">
                <img :src="info.productImgList[0]"/>
                <div class="text">
                    <p>{{ info.productTitle }}</p>
                    <span>{{ info.name }}</span>
                    <span
                    ><font>￥{{ info.price }}</font
                    ><font>X{{ info.num }}</font></span
                    >
                </div>
            </div>
            <div class="shangjia clear" Wdjinku>
                <p>{{ info.shop.name }}</p>
                <div class="uu clear" @click="shopaddress(info.shop)">
                    <img src="../assets/weiz.png"/><span>{{ info.shop.address }}</span>
                </div>
                <div class="butnodd clear">
                    <div class="clear">
                        <img src="../assets/lxx.png"/>
                        <font> <a :href="'tel:' + info.shop.phone">联系商家</a></font>
                    </div>
                </div>
            </div>
            <div class="bht clear">
                <div class="lab clear">
                    <label>订单状态:</label
                    ><span class="ck">{{ info.orderStatusText }}</span>
                </div>
                <div class="lab clear">
                    <label>订单金额:</label><span>¥{{ info.totalAmount }}</span>
                </div>
                <div class="lab clear">
                    <label>实际支付:</label><span>¥{{ info.totalAmount }}</span>
                </div>
                <div class="lab clear">
                    <label>订单编号:</label><span>{{ info.orderNo }}</span>
                </div>
                <div class="lab clear">
                    <label>下单时间:</label><span>{{ info.createTime }}</span>
                </div>
                <div class="lab clear">
                    <label>支付时间:</label><span>{{ info.payTime }}</span>
                </div>
            </div>
        </div>
        <div class="btnfi clear" v-if="info.orderStatus <= 1">
            <template v-if="info.orderStatus <= 1">
                <van-button type="default" @click="pay" :loading="payLoading"
                >立即支付
                </van-button
                >
            </template>
            <!--            <span>确认收货</span>-->
            <!--            <span>查看物流</span>-->
            <!--            <span>申请退款</span>-->
        </div>
    </div>
</template>
<script>
import { getOrderInfo, orderPay } from "@/api/order";
import { getWxConfig } from "@/api/wx";
import { Toast } from "vant";

export default {
  name: "app",
  components: {},
  data() {
    return {
      id: 0,
      info: null,
      payLoading: false
    };
  },
  methods: {
    loadOrderInfo() {
      getOrderInfo({
        id: this.id
      }).then(data => {
        this.info = data.data;
      });
    },
    pay() {
      this.payLoading = true;
      orderPay({
        id: this.id
      })
        .then(data => {
          if (data.success == true) {
            wx.ready(function() {
              wx.chooseWXPay({
                ...data.data,
                package: data.data.packageValue,
                timestamp: data.data.timeStamp,
                success: () => {
                  window.location.reload()
                },
                complete: () => {
                  // this.
                }
              });
            });
          } else {
            Toast(data.msg);
          }
          console.log(data);
        })
        .finally(() => {
          this.payLoading = false;
        });
    },
    async loadWxConfig() {
      const url = window.location.href;
      const res = await getWxConfig(url);
      if (res.success == true) {
        wx.config({
          debug: false,
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: ["chooseWXPay"]
        });
      }
    },

    shopaddress(shop){
      window.localStorage.setItem("addressLat",JSON.stringify(shop))
      this.$router.push({name:'addressLat'})
    }
  },
  created() {
    this.loadWxConfig();
    const id = this.$route.params.id;
    this.id = id;
    this.loadOrderInfo();
  }
};
</script>
<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

li {
  list-style-type: none;
}

.yijingsy {
  position: relative;
}

.erwem {
  width: 40%;
  height: 20vh;
  margin: 3% auto;
  border: solid 1px rgba(187, 187, 187, 100);
}

.yijingsy .hex {
  font-weight: 500;
  margin: 5% auto;
  text-align: center;
  color: #5bb75b;
  font-size: 12px;
}

.yijingsy .hex label {
  color: #6d6d6d;
}

.yijingsy p {
  display: block;
  text-align: center;
  font-size: 12px;
  color: rgba(16, 16, 16, 100);
}

.yijingsy .p {
  color: rgba(142, 142, 142, 100);
  margin: 4% auto 3% auto;
  font-size: 10px;
  text-align: left;
  text-indent: 3%;
}

#productStatus {
  position: absolute;
  left: 3%;
  top: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}

    #productStatus span {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 80px solid #d90101;
        border-right: 80px solid transparent;
    }

    #productStatus span em {
        position: absolute;
        top: -75px;
        left: 35px;
        margin-left: -1px;
        font-style: normal;
        height: 50px;
        width: 50px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .tect img {
        width: 15%;
        display: inline-block;
        float: left;
        margin: 2%;
    }

.tect .text {
  font-size: 13px;
  color: #919191;
  margin-left: 1%;
  margin-top: 2%;
}

    .tect .text span font {
        display: inline-block;
        float: left;
    }

    .tect .text span font:nth-child(2) {
        float: right;
        margin-right: 3%;
    }

    .tect .text span {
        margin-top: 1%;
        font-size: 12px;
        color: #bfbfbf;
        font-size: 12px;
        display: block;
    }

    .headss label {
        color: rgba(0, 189, 62, 100);
        font-size: 12px;
        text-indent: 3%;
        display: block;
        border-bottom: solid 1px rgba(187, 187, 187, 100);
        padding-bottom: 3%;
        margin: 4% 3%;
    }

    .headss .ccv p {
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        display: block;
        margin: 3% 4%;
    }

    .headss .ccv .text {
        color: rgba(16, 16, 16, 100);
        width: 80%;
        float: left;
        margin-left: 4%;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 3%;
    }

    .shangjia,
    .headss,
    .tect,
    .yijingsy {
        width: 94%;
        margin: 3% auto 0 auto;
        background: #fff;
        border-radius: 3px;
        color: #525252;
    }

    .shangjia p {
        font-weight: 600;
        font-size: 15px;
        display: block;
        margin: 3%;
    }

    .shangjia .uu {
        color: #bfbfbf;
        font-size: 12px;
        margin: 3% 2% 3% 3%;
        border-bottom: solid 1px rgba(248, 248, 248, 100);
        padding-bottom: 3%;
    }

    .shangjia .uu img {
        display: inline-block;
        float: left;
        width: 15px;
    }

    .shangjia .uu span {
        display: inline-block;
        float: left;
        margin-left: 2px;
    }

    body {
        background-color: rgba(248, 248, 248, 100);
        height: 100vh;
    }

    .ddxq {
        margin-bottom: 70px;
    }

    .butnodd {
        text-align: center;
    }

    .butnodd img,
    .butnodd font {
        display: inline-block;
        float: left;
    }

    .butnodd font {
        color: #a80101;
        font-size: 12px;
    }

    .butnodd div {
        width: 74px;
        margin: 0 auto 3% auto;
    }

    .butnodd img {
        width: 18px;
        margin-right: 4px;
    }

    .bht {
        width: 94%;
        background: #fff;
        margin: 3% auto 19% auto;
        border-radius: 3px;
    }

    .bht .lab {
        width: 100%;
    }

    .bht .lab label {
        display: inline-block;
        float: left;
        color: #bfbfbf;
        font-size: 12px;
        margin: 3% 2% 3% 3%;
    }

    .bht .lab span {
        display: inline-block;
        float: left;
        color: #727272;
        font-size: 12px !important;
        margin: 3% 0;
    }

    .bht .lab span.ck {
        color: #45b425;
    }

    .btnfi {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        background: #fff;
        border-top: solid 1px rgba(187, 187, 187, 100);
        padding: 3% 0;
    }

    .btnfi button {
        display: inline-block;
        float: right;
        /*padding: 1% 3%;*/
        margin-right: 4%;
        /*border: 1px solid rgba(187, 187, 187, 100);*/
        /*font-size: 12px;*/
        /*border-radius: 3px;*/
        color: rgba(16, 16, 16, 100);
    }
</style>
